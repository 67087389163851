export default function modal(){

  let modal = document.querySelector('.js-modal');
  let modalBtn = document.querySelectorAll('.js-modalBtn');
  let modalClose = document.querySelector('.js-modalClose');
  let modalVideo = document.querySelector('.js-modalVideo');
  let modalImg = document.querySelector('.js-modalImg');
  let body = document.querySelector('body');

  if(!modal) {
    return;
  }

  modalBtn.forEach(item=>{
    item.addEventListener('click', ()=>{

      if(item.dataset.modal === 'video') {
        modalImg.style.display = 'none';
        modalVideo.style.display = 'block';

        let videoUrl = item.dataset.video;

        modalVideo.src = videoUrl;
        modalVideo.play();
      } else {
        modalImg.style.display = 'block';
        modalVideo.style.display = 'none';
      }

      modal.classList.add('is-active');
      body.classList.add('is-active');
    });
  })

  modalClose.addEventListener('click', ()=>{
    modal.classList.remove('is-active');
    body.classList.remove('is-active');
    modalVideo.pause();
  });
}