export default function menu(){
  let hamburger = document.querySelector('.js-hamburger');
  let headerNav = document.querySelector('.js-headerNav');
  let body = document.querySelector('body');
  
  hamburger.addEventListener('click', ()=>{
    hamburger.classList.toggle('is-active');
    headerNav.classList.toggle('is-active');
    body.classList.toggle('is-active');
  })
  
  function toggleMenu(){
    hamburger.click();
  }
  
  let toggle = document.querySelectorAll('.js-toggleMenu');
  toggle = [].slice.call(toggle);
  toggle.forEach((item)=>{
    item.addEventListener('click', ()=>{
      toggleMenu();
    })
  })
}
