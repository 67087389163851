export default function aesthetic(){

  // 該当しないページは動作しない
  if(document.querySelectorAll('.js-aestheticTab').length == 0) {
    return;
  }

  let aestheticTabs = document.querySelectorAll('.js-aestheticTab');
  let aestheticTags = document.querySelectorAll('.js-aestheticTag');
  let aestheticHeading = document.querySelectorAll('[data-aestheticheading]');
  let aestheticCont = document.querySelectorAll('.js-aestheticCont');
  let aestheticItem = document.querySelectorAll('[data-aesthetic]');
  let aestheticOption = document.querySelector('.js-aestheticOption');
  let store = document.querySelectorAll('[data-store]');
  let tempArray = [];
  let tempSubArray = [];
  let init = true;

  // メインタブで項目を絞る
  function switchMainContents(currentTab){

    // 臨時 Array をクリア
    tempArray = [];

    // 項目ごとに判断（表示の可否）
    aestheticItem.forEach((item)=>{
      // 全項目一旦非表示にする
      item.classList.remove('is-active');

      aestheticHeading.forEach(item=>{
        item.dataset.aestheticheading === currentTab ? item.classList.add('is-active') : item.classList.remove('is-active');
      })

      // 項目の data 値
      let aestheticItemData = item.dataset.aesthetic;

      // 有効になるタブに一致すれば、臨時 Array に追加
      if(aestheticItemData === currentTab){
        tempArray.push(item);
      }
    });

    // サブタブの初期表示
    aestheticTags[0].click();
  }

  // サブタブで項目を絞る
  function switchSubContents(currentTag){
    // サブ臨時 Array をクリア
    tempSubArray = [];

    // メインタブから絞った臨時 Array に加えて、サブタグから絞る
    tempArray.forEach( item =>{
      // 古い項目の表示を一旦クリア
      item.classList.remove('is-active');

      // 項目の店舗タグのdata値を取得
      let tags = item.querySelectorAll('[data-tag]');
      let tagDatas = [];
      tags.forEach(item=>{
        tagDatas.push(item.dataset.tag)
      })

      // 有効になるタブに一致するかどうかを判断
      let theboolean = tagDatas.includes(currentTag);

      // 有効になるタブに一致すれば、サブ臨時 Array に追加
      if(theboolean){
        tempSubArray.push(item)
      }
    })

    // 該当項目を表示
    tempSubArray.forEach( item => {
      item.classList.add('is-active');
    })

    // オプションに項目があると表示させます
    let optionBool = [].slice.call(aestheticOption.querySelectorAll('[data-aesthetic]')).some(item=>{
      return item.classList.contains('is-active');
    })

    if(optionBool){
      aestheticOption.classList.add('is-active');
    } else {
      aestheticOption.classList.remove('is-active');
    } 


    // 各店舗独自の情報を切り替え
    store.forEach(item=>{
      item.classList.remove('is-active');

      if(item.dataset.store === currentTag) {
        item.classList.add('is-active');
      }
    })

    setTimeout(()=>{      
      aestheticCont.forEach(item => item.classList.remove('is-animating'));

      // アンカーへ飛ばす
      setTimeout(()=>{
        let target = location.hash;

        if(target === ''){
          return;
        }

        if(init === false){
          return;
        }

        let scroll = new SmoothScroll();
        let anchor = document.querySelector(target);
        scroll.animateScroll(anchor, '', { header: '#header' });

        init = false;
      }, 100)

    }, 100)

  }

  // FACIAL, BODY などのメインタブ
  aestheticTabs.forEach( item => {
    item.addEventListener('click', ()=>{
      // 押したタブの data 値
      let currentTab = item.dataset.aesthetictab;

      // リストの切り替え
      switchMainContents(currentTab);

      // アクティブなタブに切り替える
      aestheticTabs.forEach(item => item.classList.remove('is-active'));
      item.classList.add('is-active');
    })
  });

  // 神戸北野, 心斎橋, 銀座 などのサブタブ
  aestheticTags.forEach( item => {
    item.addEventListener('click', ()=>{
      aestheticCont.forEach(item => item.classList.add('is-animating'));

      setTimeout(()=>{
        // 押したタブの data 値
        let currentTag = item.dataset.aesthetictag;
  
        // リストの切り替え
        switchSubContents(currentTag);
  
        // アクティブなタブに切り替える
        aestheticTags.forEach(item => item.classList.remove('is-active'));
        item.classList.add('is-active');
      }, 300);

    })
  });

  // タイプ別のlink先でたどり着いた場合をの対処
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const type = urlParams.get('type');

  console.log(type)

  // 初期表示
  if (type === 'facial' ) {
    aestheticTabs[0].click();
  } else if (type === 'body') {
    aestheticTabs[1].click();
  } else if (type === 'facial-shinsaibashi') {
    aestheticTabs[0].click();
    aestheticTags[1].click();
  } else if (type === 'facial-ginza') {
    aestheticTabs[0].click();
    aestheticTags[2].click();
  } else {
    aestheticTabs[0].click();
  }
}
