export default function indexCampaignSlider(){

  if(!document.getElementsByClassName('js-indexCampaignSlider')[0]) {
    return;
  }

  let campaignSection = document.getElementsByClassName('p-indexCampaign')[0];
  let campaignSlider = document.getElementsByClassName('js-indexCampaignSlider')[0];
  let campaignCardList = document.getElementsByClassName('js-indexCampaignCardList')[0];
  let campaignCards = document.getElementsByClassName('js-indexCampaignCard');
  let campaignBtnLeft = document.getElementsByClassName('js-indexCampaignBtnLeft')[0];
  let campaignBtnRight = document.getElementsByClassName('js-indexCampaignBtnRight')[0];

  if(campaignCards.length === 0) {
    campaignSection.style.display = "none";
    return;
  }

  let amt = 0;
  let gutter = 60;
  let cardWidth = parseInt(window.getComputedStyle(campaignCards[0]).getPropertyValue('width'));
  let slideDistance = cardWidth + gutter;
  let clickable = true;

  function checkArrowAccessibility(){
    // スライダーの右端から window の右までの距離
    let rightOffset = window.innerWidth - campaignCardList.getBoundingClientRect().right;

    // スライダーのleftの設定値
    let leftOffset = window.getComputedStyle(campaignCardList).getPropertyValue('left');
    leftOffset = parseInt(leftOffset);

    // 左にスライドするとき、もう次がない場合、スライダー左ボタンを無効化
    if(leftOffset >= 0) {
      campaignBtnLeft.classList.add('is-disabled');
    } else {
      campaignBtnLeft.classList.remove('is-disabled');
    }

    // 右にスライドするとき、もう次がない場合、スライダー右ボタンを無効化
    if(rightOffset > 0) {
      campaignBtnRight.classList.add('is-disabled');
    } else {
      campaignBtnRight.classList.remove('is-disabled');
    }
  }

  function setCampaignSliderWidth(){
    // 全部のスライド横幅とgutterの合計
    let thisWidth = (cardWidth * campaignCards.length) + (gutter * (campaignCards.length - 1));

    campaignSlider.style.width = thisWidth + 'px';
    campaignCardList.style.width = thisWidth + 'px';
  }

  function controlCampaignBtn(direction){

    // スライドアニメーションが終わるまで押せない
    if(!clickable){
      return false;
    }

    clickable = false;

    // スライダーの右端から window の右までの距離
    let rightOffset = window.innerWidth - campaignCardList.getBoundingClientRect().right;

    // スライダーのleftの設定値
    let leftOffset = window.getComputedStyle(campaignCardList).getPropertyValue('left');
    leftOffset = parseInt(leftOffset);

    let distance = 0;

    if(direction == 'left') {
      distance = slideDistance * 1;

      // スライダーの右端が完全に表示させたまでスライド可能
      if(leftOffset < 0){
        amt += distance;
        campaignCardList.style.left = amt + 'px';
      }
    } else if(direction == 'right') {
      distance = slideDistance * -1;
      
      // スライダーの右端が完全に表示させたまでスライド可能
      if(rightOffset < 0){
        amt += distance;
        campaignCardList.style.left = amt + 'px';
      }
    }

    // 左にスライドするとき、もう次がない場合、スライダー左ボタンを無効化
    if(leftOffset + distance >= 0) {
      campaignBtnLeft.classList.add('is-disabled');
    } else {
      campaignBtnLeft.classList.remove('is-disabled');
    }

    // 右にスライドするとき、もう次がない場合、スライダー右ボタンを無効化
    if(rightOffset - distance > 0) {
      campaignBtnRight.classList.add('is-disabled');
    } else {
      campaignBtnRight.classList.remove('is-disabled');
    }

    setTimeout(()=>{
      clickable = true;
    }, 600);

  }

  function campaignInit(){
    campaignCardList.style.gap = gutter + 'px';
    setCampaignSliderWidth();

    campaignBtnRight.addEventListener('click', ()=>{
      controlCampaignBtn('right');
    })

    campaignBtnLeft.addEventListener('click', ()=>{
      controlCampaignBtn('left');
    })

    campaignBtnRight.addEventListener('touchend', ()=>{
      controlCampaignBtn('right');
    })

    campaignBtnLeft.addEventListener('touchend', ()=>{
      controlCampaignBtn('left');
    })

    checkArrowAccessibility();
  }

  window.onload = function(){
    campaignInit();
  }


}