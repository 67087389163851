import menu from './modules/menu';
import aesthetic from './modules/aesthetic';
import modal from './modules/modal';
import indexCampaign from './modules/indexCampaignSlider';
import AOS from 'aos';

modal();
menu();
aesthetic();
indexCampaign();

AOS.init({
  duration: 800,
});

$ = jQuery.noConflict();

// トップページ mv の スライダー
$('.slider').slick({
  autoplay: true,
  infinite: true,
  arrows: false,
  dots: false,
  fade: true,
  autoplaySpeed: 5000,
  speed: 2000,
  touchMove: !1,
  pauseOnFocus: !1,
  pauseOnHover: !1,
  slidesToShow: 1,
  slidesToScroll: 1,
});

// トップページ mv の svg 動画
var st0 = document.querySelectorAll('.st0');
if(st0.length != 0) {
  var anime = function () {
    new Vivus(
      'js-mv-animation',
      {
        /*type: 'scenario-sync',*/
        type: 'scenario',
        duration: 180,
        forceRender: false,
        animTimingFunction: Vivus.EASE,
      },
      function (obj) {}
    );
  };
  anime();
}


// **** アンカーリンクなどで使用 ****
const scrollTop = new SmoothScroll('a[href*="#"]', {
  speed: 600,
  header: '#header', // 固定ヘッダーがある場合、その高さ分も込みで追従してくれる
});


// scroll magic
$(function(){
  var controller = new ScrollMagic.Controller();

  $('.js-fade-in-up').each(function(){
    var obj = this;
    var $obj = $(this);
      var scene = new ScrollMagic.Scene({triggerElement: this, triggerHook: .7,})
        .on('enter',function(){
          $obj.addClass('animated');
        })
//        .addIndicators()
        .addTo(controller);
  });
  
  $('.js-fade-in').each(function(){
    var obj = this;
    var $obj = $(this);
      var scene = new ScrollMagic.Scene({triggerElement: this, triggerHook: .7,})
        .on('enter',function(){
          $obj.addClass('animated');
        })
//        .addIndicators()
        .addTo(controller);
  });

  $(window).on('load',function(){
  var obj_h_1 = $('.js-on-1').outerHeight();
  var obj_h_2 = $('.js-on-2').outerHeight();
  var obj_h_3 = $('.js-on-3').outerHeight();
  var obj_h_4 = $('.js-on-4').outerHeight();
  var obj_h_5 = $('.js-on-5').outerHeight();
  var obj_h_6 = $('.js-on-6').outerHeight();
  var obj_h_7 = $('.js-on-7').outerHeight();
  var obj_h_8 = $('.js-on-8').outerHeight();
  var obj_h_9 = $('.js-on-9').outerHeight();
  var obj_h_10 = $('.js-on-10').outerHeight();
  var obj_h_11 = $('.js-on-11').outerHeight();
  new ScrollMagic.Scene({triggerElement: ".js-on-1", duration :obj_h_1})
        .on('enter',function(){
          $('.bridal__list__flow__item').removeClass('active');
          $('.js-on-1').addClass('active');
        })
//        .addIndicators()
        .addTo(controller);
  new ScrollMagic.Scene({triggerElement: ".js-on-2", duration :obj_h_2})
        .on('enter',function(){
          $('.bridal__list__flow__item').removeClass('active');
          $('.js-on-2').addClass('active');
        })
//        .addIndicators()
        .addTo(controller);
  new ScrollMagic.Scene({triggerElement: ".js-on-3", duration :obj_h_3})
        .on('enter',function(){
          $('.bridal__list__flow__item').removeClass('active');
          $('.js-on-3').addClass('active');
        })
//        .addIndicators()
        .addTo(controller);
  new ScrollMagic.Scene({triggerElement: ".js-on-4", duration :obj_h_4})
        .on('enter',function(){
          $('.bridal__list__flow__item').removeClass('active');
          $('.js-on-4').addClass('active');
        })
//        .addIndicators()
        .addTo(controller);
  new ScrollMagic.Scene({triggerElement: ".js-on-5", duration :obj_h_5})
        .on('enter',function(){
          $('.bridal__list__flow__item').removeClass('active');
          $('.js-on-5').addClass('active');
        })
//        .addIndicators()
        .addTo(controller);
  new ScrollMagic.Scene({triggerElement: ".js-on-6", duration :obj_h_6})
        .on('enter',function(){
          $('.bridal__list__flow__item').removeClass('active');
          $('.js-on-6').addClass('active');
        })
//        .addIndicators()
        .addTo(controller);
  new ScrollMagic.Scene({triggerElement: ".js-on-7", duration :obj_h_7})
        .on('enter',function(){
          $('.bridal__list__flow__item').removeClass('active');
          $('.js-on-7').addClass('active');
        })
//        .addIndicators()
        .addTo(controller);
  new ScrollMagic.Scene({triggerElement: ".js-on-8", duration :obj_h_8})
        .on('enter',function(){
          $('.bridal__list__flow__item').removeClass('active');
          $('.js-on-8').addClass('active');
        })
//        .addIndicators()
        .addTo(controller);
  new ScrollMagic.Scene({triggerElement: ".js-on-9", duration :obj_h_9})
        .on('enter',function(){
          $('.bridal__list__flow__item').removeClass('active');
          $('.js-on-9').addClass('active');
        })
//        .addIndicators()
        .addTo(controller);
  new ScrollMagic.Scene({triggerElement: ".js-on-10", duration :obj_h_10})
        .on('enter',function(){
          $('.bridal__list__flow__item').removeClass('active');
          $('.js-on-10').addClass('active');
        })
//        .addIndicators()
        .addTo(controller);
  new ScrollMagic.Scene({triggerElement: ".js-on-11", duration :obj_h_11})
        .on('enter',function(){
          $('.bridal__list__flow__item').removeClass('active');
          $('.js-on-11').addClass('active');
        })
//        .addIndicators()
        .addTo(controller);
  new ScrollMagic.Scene({triggerElement: ".js-on-1", duration :obj_h_1})
        .on('enter',function(){
          $('.firsttime__list__flow__item').removeClass('active');
          $('.js-on-1').addClass('active');
        })
//        .addIndicators()
        .addTo(controller);
  new ScrollMagic.Scene({triggerElement: ".js-on-2", duration :obj_h_2})
        .on('enter',function(){
          $('.firsttime__list__flow__item').removeClass('active');
          $('.js-on-2').addClass('active');
        })
//        .addIndicators()
        .addTo(controller);
  new ScrollMagic.Scene({triggerElement: ".js-on-3", duration :obj_h_3})
        .on('enter',function(){
          $('.firsttime__list__flow__item').removeClass('active');
          $('.js-on-3').addClass('active');
        })
//        .addIndicators()
        .addTo(controller);
  new ScrollMagic.Scene({triggerElement: ".js-on-4", duration :obj_h_4})
        .on('enter',function(){
          $('.firsttime__list__flow__item').removeClass('active');
          $('.js-on-4').addClass('active');
        })
//        .addIndicators()
        .addTo(controller);
  new ScrollMagic.Scene({triggerElement: ".js-on-5", duration :obj_h_5})
        .on('enter',function(){
          $('.firsttime__list__flow__item').removeClass('active');
          $('.js-on-5').addClass('active');
        })
//        .addIndicators()
        .addTo(controller);
  });

});


// tab
  // $('.js-menu-trg').click(function() {
  //   var index = $('.js-menu-trg').index(this);
  //   $(".js-menu-elm").fadeIn(400);
  //   $('.js-menu-elm').css('display', 'none');
  //   $('.js-menu-elm').eq(index).css('display', 'block');
  //   $('.js-menu-trg').removeClass('active');
  //   $(this).addClass('active')
  // });


//         Q&A
$(function(){
  $('.q').click(function(){
    $(this).next('.a').slideToggle(500);
    $(this).toggleClass('click');
  });
});

//         Accordion
$(function(){
  $('.js-accordionTrigger').click(function(){
    let targetKey = $(this).data('accordion');
    let target = {};
    $('.js-accordionTarget').each(function(){
      if($(this).data('accordion') === targetKey){
        target = $(this);
      }
    });
    target.slideToggle(500);
    $(this).toggleClass('click');
  });

});